import request from "./request";

// 租户设备类型Vee预警参数查询
export const apiGetVeeConfig = (deviceTypeId) => {
  return request({
    url: `/monitor/tenant/vee/config/${deviceTypeId}`,
    method: "get",
    needJointIsolation: true,
  });
};

// 租户设备类型Vee预警参数配置
export const apiSetVeeConfig = (data) => {
  return request({
    url: "/monitor/tenant/vee/config",
    method: "post",
    data,
    needJointIsolation: true,
  });
};
